import React from "react"
import styled from "styled-components"
import { SwiperSlide } from "swiper/react"
import commillas from "../images/shapes/comillas.svg"
import curve1 from "../images/shapes/curve1.svg"
import dot from "../images/shapes/dot.svg"
import content from "../content.json"
import { colors } from "../colors"
import Slider from "./Slider"

const TestimonialSection = styled.section`
  padding: 24px;
  margin: 64px auto 48px;
  position: relative;
  max-width: 880px;

  &::before {
    content: "";
    position: absolute;
    top: -16px;
    left: 7.5%;
    width: 65px;
    height: 65px;
    background: url(${commillas}) no-repeat;
    background-size: cover;
    z-index: 12;
  }

  & .swiper-pagination {
    bottom: 16px;
    left: unset;
    right: 0;
    width: 75%;
  }

  @media (min-width: 768px) {
    &::before {
      top: -36px;
      width: 86.5px;
      height: 81px;
    }
  }
  @media (min-width: 940px) {
    margin-top: 96px;

    &::before {
      top: -72px;
      width: 131px;
      height: 124px;
    }
  }
`

const Box = styled.div`
  display: flex;
  position: relative;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    bottom: 24px;
    left: 31%;
    width: 50px;
    height: 27px;
    background: url(${curve1}) no-repeat;
    background-size: cover;
    z-index: 150;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 56px;
    left: 36%;
    width: 13px;
    height: 15px;
    background: url(${dot}) no-repeat;
    background-size: cover;
    z-index: 15;
  }

  @media (max-width: 370px) {
    margin-bottom: 30px;
  }

  @media (min-width: 600px) {
    &::before {
      height: 43px;
      width: 80px;
    }

    &::after {
      bottom: 80px;
      left: 35%;
      width: 21px;
      height: 25px;
    }
  }

  @media (min-width: 940px) {
    &::before {
      width: 160px;
      height: 86px;
    }

    &::after {
      bottom: 110px;
      width: 42px;
      height: 50px;
    }
  }
`

const Pic = styled.div`
  border-radius: 18px;
  width: 30%;
  min-width: 30%;
  max-width: 264px;
  height: auto;
  overflow: hidden;
  margin-top: 30px;
  align-self: flex-end;
  z-index: 10;
  transform: translateX(18%);
`

const TextBox = styled.div`
  border-radius: 18px;
  background: ${colors.bg1};
  width: 75%;
  padding: 20px;
  text-align: right;
  color: ${colors.button};
  align-self: flex-start;
  z-index: 1;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -40px;
    right: 16px;
    width: 65px;
    height: 65px;
    background: url(${commillas}) no-repeat;
    background-size: cover;
    z-index: 1;
    transform: scale(-1);
  }

  p {
    line-height: 1.3;
    margin: 0;
  }

  @media (min-width: 500px) {
    padding: 32px;
  }

  @media (min-width: 600px) {
    padding-left: 40px;

    &::after {
      bottom: -30px;
      right: 26px;
    }

    p {
      font-size: 1.5rem;
      line-height: 1.5;
    }
  }

  @media (min-width: 768px) {
    padding-left: 48px;

    &::after {
      width: 86.5px;
      height: 81px;
      bottom: -50px;
    }
  }

  @media (min-width: 940px) {
    padding: 48px;
    padding-left: 60px;

    &::after {
      bottom: -70px;
      right: 30px;
      width: 131px;
      height: 124px;
    }

    p {
      font-size: 2rem;
    }
  }
`

const testimonialsWithImages = content.testimonials.map(testimonial => ({
  ...testimonial,
  img: require(`../images/testimonials/${testimonial.img}`),
}))

export default function Testimonials() {
  const sliderSettings = {
    sliderStyles: {},
    slidesPerView: 1,
    spaceBetween: 32,
    breakpoints: {},
  }

  return (
    <TestimonialSection>
      <Slider sliderSettings={sliderSettings}>
        {testimonialsWithImages.map((testimonial, i) => (
          <SwiperSlide key={i}>
            <Box>
              <Pic>
                <img src={testimonial.img.default} alt="satisfied client" />
              </Pic>
              <TextBox>
                <p>{testimonial.text}</p>
                <p>- {testimonial.person}</p>
              </TextBox>
            </Box>
          </SwiperSlide>
        ))}
      </Slider>
    </TestimonialSection>
  )
}
