import React from "react"
import styled from "styled-components"
import face from "../images/face.svg"
import content from "../content.json"
import ReadMore from "./ReadMore"

const Box = styled.div`
  position: relative;
  margin: 40px auto;
  padding-left: 24px;
  max-width: 940px;

  &::before {
    content: "";
    position: absolute;
    top: -8px;
    right: -20px;
    width: 95px;
    height: 180px;
    background: url(${face}) no-repeat;
    background-size: cover;
    z-index: -1;
  }

  @media (min-width: 600px) {
    &::before {
      width: 135px;
      height: 270px;
    }
  }

  @media (min-width: 768px) {
    &::before {
      width: 190px;
      height: 360px;
      top: -16px;
    }
  }
`

const Card = styled.div`
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.16);
  border-radius: 21px;
  padding: 32px 24px;
  width: 75%;
  max-width: 650px;

  h2 {
    font-weight: 400;
    margin: 0 0 8px;
  }

  a {
    display: inline;
    font-weight: 600;
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    padding: 48px 40px;

    h2 {
      font-size: 2rem;
      font-weight: bold;
    }
  }
`

export default function AboutCard() {
  return (
    <Box>
      <Card>
        <h2>{content.pages.home.sobreCard.heading}</h2>
        <ReadMore>{content.pages.home.sobreCard.text}</ReadMore>
      </Card>
    </Box>
  )
}
