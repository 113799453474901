import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import curve1 from "../images/shapes/curve1.svg"
import curve2 from "../images/shapes/curve2.svg"
import pinkT from "../images/shapes/pink-triangle.svg"
import content from "../content.json"
import ReadMore from "./ReadMore"

const Box = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 24px;
  max-width: 940px;
  display: flex;

  ::before {
    content: "";
    position: absolute;
    bottom: 33%;
    right: 32px;
    width: 55px;
    height: 29px;
    background: url(${curve1}) no-repeat;
    background-size: cover;
    z-index: 1;
  }

  @media (min-width: 600px) {
    ::before {
      width: 110px;
      height: 58px;
      bottom: 35%;
      right: 10px;
    }
  }

  @media (min-width: 940px) {
    ::before {
      width: 161px;
      height: 86px;
    }
  }
`

const Card = styled.div`
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.16);
  border-radius: 21px;
  padding: 32px 36px 32px 24px;
  width: 84%;
  max-width: 650px;
  align-self: flex-start;
  position: relative;
  background: white;
  margin-left: 22px;

  ::before {
    content: "";
    position: absolute;
    bottom: -28px;
    right: -12%;
    width: 37%;
    height: calc(158px + 5vw);
    background: url(${pinkT}) no-repeat;
    background-size: cover;
    z-index: -1;
  }

  ::after {
    content: "";
    position: absolute;
    top: 20%;
    left: -22px;
    width: 29px;
    height: 38px;
    background: url(${curve2}) no-repeat;
    background-size: cover;
    z-index: 12;
  }

  h2 {
    font-weight: 400;
    margin: 0 0 8px;
  }

  p {
    display: inline;
    line-height: 1.4;

    span {
      display: none;
    }
  }

  @media (min-width: 600px) {
    ::before {
      width: 47%;
      right: -20%;
      height: calc(178px + 5vw);
    }

    ::after {
      width: 58px;
      height: 76px;
      left: -40px;
    }

    p {
      line-height: 1.65;

      span {
        display: inline;
      }
    }
  }

  @media (min-width: 768px) {
    padding: 32px 40px;
    ::before {
      right: -15%;
      height: 140%;
      bottom: -25%;
    }

    h2 {
      font-size: 2rem;
      font-weight: bold;
    }
  }

  @media (min-width: 940px) {
    padding: 48px;
    margin-left: 42px;

    ::after {
      width: 85px;
      height: 112px;
      left: -60px;
    }
  }
`

const Pic = styled.div`
  width: 35%;
  max-width: 337px;
  height: auto;
  overflow: hidden;
  margin-top: 40px;
  margin-left: -18%;
  align-self: flex-end;
  z-index: 10;

  @media (max-width: 400px) {
    margin-top: 34%;
  }

  @media (min-width: 768px) {
    margin-left: -8%;
    margin-top: 20px;
  }

  @media (min-width: 940px) {
    margin-top: 0;
  }
`

export default function Introduction() {
  return (
    <Box>
      <Card>
        <h2>{content.pages.home.introduction.heading}</h2>
        <ReadMore>{content.pages.home.introduction.text}</ReadMore>
      </Card>

      <Pic>
        <StaticImage
          src="../images/marialy/marialy1.png"
          alt="satisfied client"
          placeholder="blurred"
          style={{ height: "100%" }}
        />
      </Pic>
    </Box>
  )
}
