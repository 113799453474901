import React from "react"
import styled from "styled-components"
import { SwiperSlide } from "swiper/react"
import content from "../content.json"
import Slider from "./Slider"

const SliderWrapper = styled.div`
  & .swiper-slide {
    width: 145px;

    @media (min-width: 940px) {
      width: max-content;
    }
  }
`

const Therapy = styled.div`
  height: auto;
  padding: 16px 24px;
  border-radius: 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.16);
  max-width: 145px;
  & img {
    height: 58px;
  }

  & p {
    margin-bottom: 0;
    line-height: 1.3;
  }

  @media (min-width: 940px) {
    box-shadow: none;
    flex-direction: row;
    align-items: center;
    min-width: max-content;

    & p {
      margin-left: 16px;
    }
  }
`

const therapiesWithImages = content.therapies.map(therapy => ({
  ...therapy,
  img: require(`../images/therapies/${therapy.img}`),
}))

export default function Therapies() {
  const sliderSettings = {
    sliderStyles: { padding: "48px 24px", maxWidth: 1150 },
    slidesPerView: "auto",
    spaceBetween: 22,
    breakpoints: {
      768: {
        spaceBetween: 30,
      },
      940: {
        spaceBetween: 48,
      },
    },
  }

  return (
    <SliderWrapper>
      <Slider sliderSettings={sliderSettings}>
        {therapiesWithImages.map((type, i) => (
          <SwiperSlide key={i}>
            <Therapy>
              <img src={type.img.default} alt={type.type} />
              <p>{type.type}</p>
            </Therapy>
          </SwiperSlide>
        ))}
      </Slider>
    </SliderWrapper>
  )
}
