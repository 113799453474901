import * as React from "react"
import HomeHero from "../components/HomeHero"
import Introduction from "../components/Introduction"
import Layout from "../components/layout/Layout"
import Seo from "../components/Seo"
import AboutCard from "../components/SobreCard"
import TalleresSlider from "../components/TalleresSlider"
import Testimonials from "../components/Testimonials"
import Therapies from "../components/TherapiesHomepage"
import content from '../content.json'

const IndexPage = () => (
  <Layout>
    <Seo {...content.pages.seo} />
    <HomeHero />
    <Therapies />
    <AboutCard />
    <Testimonials />
    <Introduction />
    <TalleresSlider />
  </Layout>
)

export default IndexPage
