import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import curve1 from "../images/shapes/curve1.svg"
import curve2 from "../images/shapes/curve2.svg"
import dot from "../images/shapes/dot.svg"
import gc from "../images/shapes/green-circle.svg"
import content from "../content.json"
import { colors } from "../colors"

const Hero = styled.section`
  position: relative;
  padding-top: 96px;

  &::before {
    content: "";
    position: absolute;
    top: -75px;
    right: -400px;
    width: 492px;
    height: 492px;
    background: url(${gc}) no-repeat;
    background-size: 40%;
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 365px;
    left: -30px;
    width: 161px;
    height: 86px;
    background: url(${curve1}) no-repeat;
    background-size: 50%;
    z-index: -1;
  }

  @media (min-width: 768px) {
    padding-top: 120px;

    &::before {
      background-size: 100%;
      top: -250px;
      right: -250px;
    }

    &::after {
      background-size: 100%;
      top: 80px;
      left: -50px;
    }
  }
`

const ForShapes = styled.div`
  &::before {
    content: "";
    position: absolute;
    top: 45px;
    right: -30px;
    width: 161px;
    height: 86px;
    background: url(${curve1}) no-repeat;
    background-size: 50%;
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 8px;
    right: 75px;
    width: 42.5px;
    height: 50.2px;
    background: url(${dot}) no-repeat;
    background-size: 50%;
    z-index: -1;
  }

  @media (min-width: 768px) {
    display: flex;

    &::before {
      background-size: 100%;
      top: 90px;
      right: 120px;
    }

    &::after {
      background-size: 100%;
      top: 30px;
      right: 220px;
    }
  }
`

const HeroText = styled.div`
  max-width: fit-content;
  margin: 0 auto 48px;
  z-index: 10;

  @media (min-width: 768px) {
    margin: 48px 0 0 24px;
  }
`

const H1 = styled.h1`
  margin: 0;
  line-height: 0.95;
  white-space: nowrap;

  @media (min-width: 768px) {
    line-height: 1.12;
  }
`

const Tagline = styled.p`
  @media (min-width: 768px) {
    line-height: 1.5;
    margin-bottom: 24px;
  }
`

const Cta = styled.button`
  background: ${colors.button};
  padding: 8px 16px;
  outline: none;
  border: none;
  color: ${colors.text};
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  margin-right: 16px;
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.26);
  }
`

const LightText = styled.p`
  font-size: 0.875rem;
  color: ${colors.lightText};
  max-width: 70%;
  line-height: 1.5;
  margin-top: 16px;

  @media (min-width: 768px) {
    max-width: 100%;
    margin-top: 24px;
    font-size: 1rem;
  }
`

const Pic = styled.div`
  width: 75%;
  position: relative;

  @media (min-width: 768px) {
    width: 55%;
    max-width: 592px;
    order: -1;

    &::before {
      content: "";
      position: absolute;
      top: 45%;
      right: 6%;
      width: 86px;
      height: 113px;
      background: url(${curve2}) no-repeat;
      background-size: 100%;
      z-index: 1;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 12%;
      right: 24%;
      width: 42.5px;
      height: 50.2px;
      background: url(${dot}) no-repeat;
      background-size: 100%;
      z-index: 1;
    }
  }
`
export default function HomeHero() {
  return (
    <Hero>
      <ForShapes>
        <HeroText>
          <h5 style={{ margin: 0, color: colors.button }}>
            {content.pages.home.hero.h5}
          </h5>
          <H1>
            {content.pages.home.hero.heading.part1}
            <br />
            {content.pages.home.hero.heading.part2}
          </H1>
          <Tagline>
            {content.pages.home.hero.tagline.part1}
            <br />
            {content.pages.home.hero.tagline.part2}
          </Tagline>
          <div>
            <Link to={content.pages.home.hero.linkBtn.link}>
              <Cta>{content.pages.home.hero.linkBtn.text}</Cta>
            </Link>
            <Link to={content.pages.home.hero.link.link}>
              {content.pages.home.hero.link.text}
            </Link>
          </div>
          <LightText>{content.pages.home.hero.lightText}</LightText>
        </HeroText>
        <Pic>
          <StaticImage
            src="../images/home-hero-pic.png"
            alt=""
            placeholder="blurred"
          />
        </Pic>
      </ForShapes>
    </Hero>
  )
}
